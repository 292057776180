<template>
  <section>
    <div v-if="show === 'signin'">
      <VCard class="loginForm elevation-20">
        <img
          src="../assets/img/logo.png"
          alt="Logo"
        >
        <h1>Hello!</h1>
        <h4>Please Login To Continue</h4>
        <VForm
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div class="loginInput">
            <VTextField
              v-model="email"
              type="email"
              background-color="background"
              autofocus
              :rules="emailRules"
              label="Email"
              required
              solo
              flat
              class="w100"
              @keyup.enter="submit"
            />

            <VTextField
              v-model="password"
              background-color="background"
              :rules="passwordRules"
              label="Password"
              required
              solo
              flat
              class="w100"
              :type="showPass ? 'text' : 'password'"
              :append-icon="showPass? 'visibility' : 'visibility_off'"
              @click:append="showPass = !showPass"
              @keyup.enter="submit"
            />
          </div>
          <VBtn
            block
            depressed
            round
            color="primary"
            @click="submit"
          >
            Login
          </VBtn>
        </VForm>
      </VCard>
    </div>
    <div v-if="show === 'loading'">
      <VCard class="loadingCard elevation-20">
        <div>
          <VProgressCircular
            :size="90"
            width="3"
            color="primary"
            indeterminate
          >
            <VIcon
              x-large
              color="primary"
            >
              mdi-account
            </VIcon>
          </VProgressCircular>
        </div>
      </VCard>
    </div>
    <div v-if="show === 'error'">
      <VCard class="errCard elevation-20">
        <div>
          <p> {{ errMsgTitle }} </p>
          <span> {{ errMsg }} </span>
        </div>
        <div class="mt-5">
          <VBtn
            depressed
            round
            color="primary"
            @click="retry()"
          >
            Try Again
          </VBtn>
        </div>
      </VCard>
    </div>
  </section>
</template>

<script>
import fire from '../conf/firebaseAuth';

export default {
  data: () => ({
    showPass: false,
    show: '',
    errMsgTitle: '',
    errMsg: '',
    valid: true,
    email: '',
    password: '',
    emailRules: [
      v => !!v || 'Email can not be empty',
      v => /.+@.+/.test(v) || 'Invalid email address',
    ],
    passwordRules: [v => !!v || 'Password can not be empty'],
  }),
  beforeMount() {
    if (this.$store.state.currentUser != null) {
      this.$router.push('/dashboard');
    } else {
      this.show = 'signin';
    }
  },
  methods: {
    retry() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.show = 'loading';
        fire
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            setTimeout(() => {
              this.$router.push('/dashboard');
              this.show = '';
            }, 100);
          })
          .catch((e) => {
            this.show = 'error';
            if (e.code === 'auth/wrong-password') {
              this.errMsgTitle = 'Warning';
              this.errMsg = 'Invalid Or Wrong Password';
            } else if (e.code === 'auth/user-not-found') {
              this.errMsgTitle = 'Warning';
              this.errMsg = 'Invalid Or Wrong Email Address';
            } else if (e.code === 'auth/network-request-failed') {
              this.errMsgTitle = 'Internet Issue';
              this.errMsg = 'Connection Failed';
            } else {
              this.errMsgTitle = 'Error';
              this.errMsg = 'An Error Occured';
            }
          });
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../assets/stylus/login';
</style>
